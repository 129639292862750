<template>
  <div class="rack">
    <div class="left">
      <div class="img" @click="openModal(data.img.url, data.id)">
        <img :src="data.img.url" alt="rack" />
      </div>
      <div class="rack-info">
        <VueText sizeLevel="9" class="rack-name" weightLevel="3">
          {{ `Raf-${data.rackNumber}` }}
        </VueText>
        <VueText sizeLevel="8" color="grey-30" weightLevel="3" class="rack-date">
          {{ data.date }}
        </VueText>
      </div>
    </div>
    <div class="right">
      <BrandButton
        as="router-link"
        :target="{ name: 'QRReader', params: { id: data.id, isSecondUrl: data.isSecondUrl } }"
        :size="sizes.medium"
        iconColor="#ffffff"
        shape="rounded"
        :radius="50"
        class="change-btn"
      >
        <VueText sizeLevel="6" weightLevel="3">Değiştir</VueText>
      </BrandButton>
      <VueIcon
        :isIconButton="true"
        :iconName="icons.trash.name"
        :size="sizes.xLarge"
        :width="icons.trash.width"
        :height="icons.trash.height"
        class="remove-rack"
        @click="removeRack"
      >
      </VueIcon>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';

export default {
  name: 'Rack',
  components: {
    VueText,
    VueIcon,
    BrandButton,
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
  },
  methods: {
    openModal(imgSrc, id) {
      const modalConfig = {
        isModalOpened: true,
        modalConfirmationText: '',
        firstButtonText: 'Değiştir',
        firstButtonFn: () => {
          this.$router.push({ name: 'QRReader', params: { id: id } });
        },
        imgSrc,
        id,
      };
      this.$store.dispatch('app/setModalConfig', modalConfig);
    },
    removeRack() {
      this.$emit('removeRack', this.data.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.rack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 0.5px solid #b4c2d3;
  .right,
  .left {
    display: flex;
    align-items: center;
  }
  .img {
    width: 151px;
    height: 164px;
    flex-shrink: 0;
    margin-right: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &-info {
    margin-right: 15px;
  }
  &-name {
    margin-bottom: 22px;
  }
  &-date {
    white-space: nowrap;
  }
}
.change-btn {
  width: 145px !important;
  height: 50px !important;
  margin-right: 45px;
  border-radius: 36px;
}
.remove-rack {
  cursor: pointer;
}
</style>
